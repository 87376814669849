import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkManager from "../../app/managers/networkManager";
import { config } from "../../app/utils/config";
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";

const initialState = {
  projectsList: [],
  onCreateProject: null, //for edit too now
  onCreateCategories: [],
  onCreateSubCategories: [],
  projectModalVisible: false,
  createProjectModalVisible: false,
  respProjectSaved: null,
  respProjectDelete: null,
  respProjectElement: null,
  respProjectDefForm: null,
  respProjectMembers: null,
  respPublicFrom: null,
  bankAptitudesOnCreation: [],
  addDefaultProjectFormResponse: [],
  selectedView: 0,
  addedAptitudes: [],
  projectAptitudes: [],
  addElementModalVisibility: null,
  projectMode: null,
  collabsNames: [],
  projectCollabs: [],
  projectManagers: [],
  addMembersModalVisibility: false,
  addMembersMode: null,
  adminConfigModal: false,
  autoQuestionsModalVisibility: false,
  questionsParentAptitude: null,
  aptitudeQuestionsList: [],
  respAddEditQuestion: null,
  collabProjectFormVisibility: false,
  onAnswerProject: null,
  projectAptitudesWithValues: [],
  respAptitudeQuestion: null,
  projectProgress: 0,
  projectProgressDetails: [],
  respAptitudeEdit: null,
  selectedAptitudeForQuestion: null,
  addQuestionModalVisibility: false,
  currentQuestion: null,
  collabMcqModalVisibility: false,
  collabMcqModalIdProject: null,
  collabMcqQuestions: [],
  collabMcqResp: null,
  collabMcqResultsModalVisibility: false,
  collabIdMcqResults: null,
  collabMcqResults: [],
  respExcelUpload: null,
  sessionStartDate: null,
  sessionEndDate: null,
  lastResponseNumber: null,
  responsesDatesHistory: []
}

export const vcompSlice = createSlice({
  name: "vcomp",
  initialState,
  reducers: {
    setProjectModalVisible: (state, action) => {
      state.projectModalVisible = action.payload
    },
    setCreateProjectModalVisible: (state, action) => {
      state.createProjectModalVisible = action.payload
    },
    setOnCreateProject: (state, action) => {
      state.onCreateProject = action.payload
    },
    setRespProjectSaved: (state, action) => {
      state.respProjectSaved = action.payload;
    },
    setRespProjectDelete: (state, action) => {
      state.respProjectDelete = action.payload;
    },
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
    setAddedAptitudes: (state, action) => {
      state.addedAptitudes = action.payload;
    },
    setProjectAptitudes: (state, action) => {
      state.projectAptitudes = action.payload;
    },
    setAddElementModalVisibility: (state, action) => {
      state.addElementModalVisibility = action.payload;
    },
    setSelectedAptitudeForQuestion: (state, action) => {
      state.selectedAptitudeForQuestion = action.payload;
    },
    setProjectMode: (state, action) => {
      state.projectMode = action.payload;
    },
    setRespProjectElement: (state, action) => {
      state.respProjectElement = action.payload;
    },
    setRespProjectMembers: (state, action) => {
      state.respProjectMembers = action.payload;
    },
    setRespProjectDefForm: (state, action) => {
      state.respProjectDefForm = action.payload;
    },
    setAddMembersModalVisibility: (state, action) => {
      state.addMembersModalVisibility = action.payload;
    },
    setAddMembersMode: (state, action) => {
      state.addMembersMode = action.payload;
    },
    setAdminConfigModal: (state, action) => {
      state.adminConfigModal = action.payload
    },
    setRespPublicFrom: (state, action) => {
      state.respPublicFrom = action.payload
    },
    setAutoQuestionsModalVisibility: (state, action) => {
      state.autoQuestionsModalVisibility = action.payload;
    },
    setQuestionsParentAptitude: (state, action) => {
      state.questionsParentAptitude = action.payload
    },
    setAptitudeQuestionsList: (state, action) => {
      state.aptitudeQuestionsList = action.payload;
    },
    setRespAddEditQuestion: (state, action) => {
      state.respAddEditQuestion = action.payload;
    },
    setCollabProjectFormVisibility: (state, action) => {
      state.collabProjectFormVisibility = action.payload;
    },
    setOnAnswerProject: (state, action) => {
      state.onAnswerProject = action.payload;
    },
    setProjectProgress: (state, action) => {
      state.projectProgress = action.payload
    },
    setProjectProgressDetails: (state, action) => {
      state.projectProgressDetails = action.payload
    },
    setProjectMembers: (state, action) => {
      state.projectCollabs = action.payload
    },
    setProjectManagers: (state, action) => {
      state.projectManagers = action.payload
    },
    setAddQuestionModalVisibility: (state, action) => {
      state.addQuestionModalVisibility = action.payload;
    },
    setCurrentQuestion(state, action) {
      state.currentQuestion = action.payload;
    },
    setCollabMcqModalVisibility(state, action) {
      state.collabMcqModalVisibility = action.payload
    },
    setCollabMcqModalIdProject(state, action) {
      state.collabMcqModalIdProject = action.payload
    },
    setCollabMcqQuestions(state, action) {
      state.collabMcqQuestions = action.payload
    },
    setCollabMcqResp(state, action){
      state.collabMcqResp = action.payload 
    },
    setCollabMcqResultsModalVisibility(state, action){
      state.collabMcqResultsModalVisibility = action.payload;
    },
    setCollabIdMcqResults(state, action){
      state.collabIdMcqResults = action.payload;
    },
    setCollabMcqResults(state, action){
      state.collabMcqResults = action.payload
    },
    setRespExcelUpload(state, action){
      state.respExcelUpload = action.payload;
    },
    setSessionEndDate(state, action) {
      state.sessionEndDate = action.payload
    },
    setSessionStartDate(state, action) {
      state.sessionStartDate = action.payload
    },
    setLastResponseNumber(state, action){
      state.lastResponseNumber = action.payload;
    },
    setResponseDatesHistory(state, action){
      state.responsesDatesHistory = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addEditVcompProject.fulfilled, (state, action) => {
      if (action.payload.responseData &&
        action.payload.responseData.status
      ) {
        if (action.payload.responseData.added) {
          state.onCreateProject = {
            ...state.onCreateProject,
            id: action.payload.responseData.added
          }

        }
        state.respProjectSaved = action.payload.responseData.message;
      } else {
        state.onCreateProject = null;
        state.respProjectSaved = "Something went wrong";
      }
    })
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectDelete = action.payload.responseData.message;
      }
    })
    builder.addCase(getBankAptitudesOnCreation.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.bankAptitudesOnCreation = action.payload.responseData;
      }
    })
    builder.addCase(getAptitudes.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.aptitudes = action.payload.responseData.aptitudes;
      }
    })
    builder.addCase(reorderProjectForm.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectSaved = action.payload.responseData.message;
      }
    })
    builder.addCase(getProjects.fulfilled, (state, action) => {
      if (action.payload.responseData) {
        state.projectsList = action.payload.responseData.projects;
      }
    })
    builder.addCase(addEditCategory.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectElement = action.payload.responseData.message;
      }
    })
    builder.addCase(addEditAptitude.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectElement = action.payload.responseData.message;
      }
    })
    builder.addCase(addDefaultProjectForm.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectDefForm = action.payload.responseData.message;
      }
    })
    builder.addCase(getProjectAptitudes.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.projectAptitudes = action.payload.responseData;
      }
    })
    builder.addCase(deleteProjectCatSub.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectElement = action.payload.responseData.message;
      }
    })
    builder.addCase(deleteProjectAptitude.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectElement = action.payload.responseData.message;
      }
    })
    builder.addCase(getCollabsNames.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.collabsNames = action.payload.responseData.collabs;
      }
    })
    builder.addCase(getProjectMembersAndManagers.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.projectCollabs = action.payload.responseData.collabs;
        state.projectManagers = action.payload.responseData.managers;
      }
    })
    builder.addCase(addRemoveProjectCollabsManagers.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respProjectMembers = action.payload.responseData.message;
      }
    })
    builder.addCase(reorderPublicForm.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respPublicFrom = action.payload.responseData.message;
      }
    })
    builder.addCase(hidePublicCatSub.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respPublicFrom = action.payload.responseData.message;
      }
    })
    builder.addCase(hidePublicAptitudes.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respPublicFrom = action.payload.responseData.message;
      }
    })
    builder.addCase(getAptitudeQuestions.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.aptitudeQuestionsList = action.payload.responseData.questions;
      }
    })
    builder.addCase(addEditQuestion.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respAddEditQuestion = action.payload.responseData.message;
      }
    })
    builder.addCase(getProjectAptitudesWithValues.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.projectAptitudesWithValues = action.payload.responseData;
      }
    })
    builder.addCase(answerAptitudeDailyBase.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.respAptitudeQuestion = action.payload.responseData.message;
      }
    })
    builder.addCase(getProjectProgress.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.projectProgress = action.payload.responseData.progress;
        state.projectProgressDetails = action.payload.responseData.categories;
        state.sessionStartDate = action.payload.responseData.start_date;
        state.sessionEndDate = action.payload.responseData.end_date;
      }
    })
    builder.addCase(editResponseManager.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.respAptitudeEdit = action.payload.responseData.message;
      }
    })
    builder.addCase(getProjectQuestions.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.collabMcqQuestions = action.payload.responseData.questions;
      }
    })
    builder.addCase(recordMcqResponsePerProject.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.collabMcqResp = action.payload.responseData.message;
      }
    })
    builder.addCase(recordMcqResponsePerProject.rejected, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.collabMcqResp = action.payload.responseData.message;
      }
    })
    builder.addCase(getCollabMcqResults.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.collabMcqResults = action.payload.responseData.aptitudes;
      }
    })
    builder.addCase(importProjectFromExcel.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respExcelUpload = action.payload.responseData.message;
      }
    })
    builder.addCase(importProjectFromExcel.rejected, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.message) {
        state.respExcelUpload = action.payload.responseData.message;
      }
    })
    builder.addCase(getCollabResponseHistory.fulfilled, (state, action) => {
      if (action.payload.responseData && action.payload.responseData.status) {
        state.responsesDatesHistory = action.payload.responseData.responses_history.sort((a, b) => b.response_number - a.response_number);;
      }
    })
  }
})

export const getBankAptitudesOnCreation = createAsyncThunk(
  "vcomp/getBankAptitudesOnCreation",
  async () => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getBankAptitudesOnCreation
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAptitudes = createAsyncThunk(
  "vcomp/getAptitudes",
  async () => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getAptitudes
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addEditVcompProject = createAsyncThunk(
  "vcomp/addEditVcompProject",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addEditVcompProject,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteProject = createAsyncThunk(
  "vcomp/deleteProject",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.deleteProject,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addDefaultProjectForm = createAsyncThunk(
  "vcomp/addDefaultProjectForm",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addDefaultProjectForm,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reorderProjectForm = createAsyncThunk(
  "vcomp/reorderProjectForm",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.reorderProjectForm,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjects = createAsyncThunk(
  "vcomp/getProjects",
  async () => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjects
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addEditCategory = createAsyncThunk(
  "vcomp/addEditCategory",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addEditCategory,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addEditAptitude = createAsyncThunk(
  "vcomp/addEditAptitude",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addEditAptitude,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectAptitudes = createAsyncThunk(
  "vcomp/getProjectAptitudes",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjectAptitudes,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteProjectCatSub = createAsyncThunk(
  "vcomp/deleteProjectCatSub",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.deleteProjectCatSub,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteProjectAptitude = createAsyncThunk(
  "vcomp/deleteProjectAptitude",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.deleteProjectAptitude,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectMembersAndManagers = createAsyncThunk(
  "vcomp/getProjectMembersAndManagers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjectMembersAndManagers,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCollabsNames = createAsyncThunk(
  "vcomp/getCollabsNames",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getCollabsNames,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addRemoveProjectCollabsManagers = createAsyncThunk(
  "vcomp/addRemoveProjectCollabsManagers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addRemoveProjectCollabsManagers,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reorderPublicForm = createAsyncThunk(
  "vcomp/reorderPublicForm",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.reorderPublicForm,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const hidePublicCatSub = createAsyncThunk(
  "vcomp/hidePublicCatSub",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.hidePublicCatSub,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const hidePublicAptitudes = createAsyncThunk(
  "vcomp/hidePublicAptitudes",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.hidePublicAptitudes,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAptitudeQuestions = createAsyncThunk(
  "vcomp/getAptitudeQuestions",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getAptitudeQuestions,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectAptitudesWithValues = createAsyncThunk(
  "vcomp/getProjectAptitudesWithValues",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjectAptitudesWithValues,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const answerAptitudeDailyBase = createAsyncThunk(
  "vcomp/answerAptitudeDailyBase",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.answerAptitudeDailyBase,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getProjectProgress = createAsyncThunk(
  "vcomp/getProjectProgress",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjectProgress,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const editResponseManager = createAsyncThunk(
  "vcomp/editResponseManager",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.editResponseManager,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
export const activateMcqCollabs = createAsyncThunk(
  "vcomp/activateMcqCollabs",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.activateMcqCollabs,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const addEditQuestion = createAsyncThunk(
  "vcomp/addEditQuestion",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addEditQuestion,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addEditQuestionAnswers = createAsyncThunk(
  "vcomp/addEditQuestionAnswers",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.addEditQuestionAnswers,
        body
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "vcomp/deleteQuestion",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.deleteQuestion,
        body
      );
      showSuccessToast("Question deleted successfully");
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);

export const deleteQuestionAnswer = createAsyncThunk(
  "vcomp/deleteQuestionAnswer",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.deleteQuestionAnswer,
        body
      );
      showSuccessToast("Answer deleted successfully");
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);
export const aptitudeMcqVisibility = createAsyncThunk(
  "vcomp/aptitudeMcqVisibility",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.aptitudeMcqVisibility,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);
export const getProjectQuestions = createAsyncThunk(
  "vcomp/getProjectQuestions",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getProjectQuestions,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);

export const recordMcqResponsePerProject = createAsyncThunk(
  "vcomp/recordMcqResponsePerProject",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.recordMcqResponsePerProject,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);

export const getCollabMcqResults = createAsyncThunk(
  "vcomp/getCollabMcqResults",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getCollabMcqResults,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);

export const importProjectFromExcel = createAsyncThunk(
  "vcomp/importProjectFromExcel",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.importProjectFromExcel,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);

export const getCollabResponseHistory = createAsyncThunk(
  "vcomp/getCollabResponseHistory",
  async (body) => {
    try {
      let response = await NetworkManager.getInstance().postRequest(
        config.apiPaths.vcomp.getCollabResponseHistory,
        body
      );
      return response;
    } catch (error) {
      showErrorToast("Something went wrong");
      return error;
    }
  }
);


export const {
  setProjectModalVisible,
  setCreateProjectModalVisible,
  setOnCreateProject,
  setRespProjectDelete,
  setRespProjectSaved,
  setSelectedView,
  setAddedAptitudes,
  setProjectAptitudes,
  setAddElementModalVisibility,
  setProjectMode,
  setRespProjectElement,
  setRespProjectDefForm,
  setRespProjectMembers,
  setAddMembersModalVisibility,
  setAddMembersMode,
  setAdminConfigModal,
  setRespPublicFrom,
  setAutoQuestionsModalVisibility,
  setQuestionsParentAptitude,
  setAptitudeQuestionsList,
  setRespAddEditQuestion,
  setCollabProjectFormVisibility,
  setOnAnswerProject,
  setProjectProgress,
  setProjectProgressDetails,
  setProjectManagers,
  setProjectMembers,
  setSelectedAptitudeForQuestion,
  setAddQuestionModalVisibility,
  setCurrentQuestion,
  setCollabMcqModalVisibility,
  setCollabMcqModalIdProject,
  setCollabMcqQuestions,
  setCollabMcqResp,
  setCollabMcqResultsModalVisibility,
  setCollabIdMcqResults,
  setCollabMcqResults,
  setRespExcelUpload,
  setSessionStartDate,
  setSessionEndDate,
  setLastResponseNumber,
  setResponseDatesHistory

} = vcompSlice.actions

export default vcompSlice.reducer;