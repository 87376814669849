import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import {
    setCreateProjectModalVisible,
    setOnCreateProject,
    addEditVcompProject,
    setRespProjectDelete,
    setRespProjectSaved,
    deleteProject,
    getBankAptitudesOnCreation,
    addDefaultProjectForm,
    reorderProjectForm,
    addEditCategory,
    addEditAptitude,
    setAddedAptitudes,
    getProjectAptitudes,
    setProjectAptitudes,
    setProjectMode,
    setAddElementModalVisibility,
    getProjects,
    setRespProjectElement,
    setRespProjectDefForm,
    deleteProjectCatSub,
    deleteProjectAptitude,
    addRemoveProjectCollabsManagers,
    getCollabsNames,
    getProjectMembersAndManagers,
    setRespProjectMembers,
    setAddMembersModalVisibility,
    setAddMembersMode,
    getProjectProgress,
    setProjectProgress,
    setProjectProgressDetails,
    setProjectMembers,
    setProjectManagers,
    setSelectedAptitudeForQuestion,
    activateMcqCollabs,
    setCollabMcqModalVisibility,
    setCollabIdMcqResults,
    setCollabMcqResultsModalVisibility,
    setSessionEndDate,
    setSessionStartDate,
    setLastResponseNumber
} from "./vcompSlice";
import { useDispatch, useSelector } from "react-redux";
import Langs, { Localize } from "../../app/lang/langs";
import { Card, Col, OverlayTrigger, Row } from "react-bootstrap";
import styles from './VcompCompnents.module.css'
import './VcompCompnentsStyle.css'
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import addImageIcon from '../../assets/images/addProjectImage.png'
import deleteImageIcone from '../../assets/images/deleteImageIcon.png'
import ImageButton from "../buttons/ImageButton";
import { Calendar } from 'primereact/calendar';
import { InputNumber } from "primereact/inputnumber";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { showErrorToast, showSuccessToast } from "../../app/utils/helpers";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import nextTab from "../../assets/images/nextTab.png"
import prevTab from "../../assets/images/prevTab.png"
import nextTabFocus from "../../assets/images/nextTabFocus.png"
import prevTabFocus from "../../assets/images/prevTabFoxus.png"
import { ThemeButton } from '../buttons/ThemeButton';
import { Tree } from 'primereact/tree';
import addIcon from '../../assets/images/addIconGray.png'
import addIconFocus from '../../assets/images/addIconOrange.png'
import deleteIcon from '../../assets/images/delete.png'
import deleteIconFocus from '../../assets/images/delete-orange.png'
import editIcon from '../../assets/images/penGray.png'
import editIconFocus from '../../assets/images/penOrange.png'
import AddElementModal from "./AddElementModal";
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AddMembersModal from "./AddMembersModal";
import ProgressView from "./ProgressView";
import mcqResults from '../../assets/images/mcqResults.png'
import mcqResultsFocus from '../../assets/images/mcqResultsFocus.png'
import McqResultsModal from "./McqResultsModal";
import excelIcon from '../../assets/images/excelIcon.png'
import excelIconFocus from '../../assets/images/excelIconFocus.png'
import ExcelInputModal from "./excelInputModal";
import { Button } from "primereact/button";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { ToggleButton } from "primereact/togglebutton";





export default function CreateProjectModal() {

    const dispatch = useDispatch();

    const visible = useSelector((state) => state.vcomp.createProjectModalVisible);
    const isMobile = useSelector((state) => state.settings.isMobile);
    const onCreateProject = useSelector((state) => state.vcomp.onCreateProject);
    const respProjectSaved = useSelector((state) => state.vcomp.respProjectSaved);
    const respProjectDelete = useSelector((state) => state.vcomp.respProjectDelete);
    const respProjectElement = useSelector((state) => state.vcomp.respProjectElement);
    const respProjectDefForm = useSelector((state) => state.vcomp.respProjectDefForm);
    const respProjectMembers = useSelector((state) => state.vcomp.respProjectMembers);
    const bankAptitudesOnCreation = useSelector((state) => state.vcomp.bankAptitudesOnCreation);
    const projectAptitudes = useSelector((state) => state.vcomp.projectAptitudes);
    const mode = useSelector((state) => state.vcomp.projectMode);
    const managersIds = useSelector((state) => state.vcomp.projectManagers);
    const collabsIds = useSelector((state) => state.vcomp.projectCollabs);
    const collabsNames = useSelector((state) => state.vcomp.collabsNames);

    const fileInputRef = useRef(null);
    const categoriesMenuRef = useRef(null);
    const dtCollabs = useRef(null)
    const dtManagers = useRef(null)

    const [activeIndex, setActiveIndex] = useState(0);
    const [isInDatabase, setIsIOnDatabase] = useState(false);
    const [datesList, setDatesList] = useState([]);
    const [addedApt, setAddedApt] = useState([])
    const [nodes, setNodes] = useState([]);
    const [nodesInProject, setNodesInProject] = useState([])
    const [selectedKeys, setSelectedKeys] = useState(null);
    const [excludedKey, setExcludedKey] = useState([])
    const [collabsList, setCollabsList] = useState([])
    const [mangersList, setMangersList] = useState([])
    const [excelModalVisibility, setExcelModalVisibility] = useState(false)

    const [project, setProject] = useState({
        nom: '',
        start_date: null,
        end_date: null,
        days_limit: 1,
        active: true,
        image_src: null
    });

    const [validFields, setValidFields] = useState({
        nom: false,
        start_date: false,
        tried: false,
    });

    const [expandedKeysEdit, setExpandedKeysEdit] = useState({ '0': true, '0-0': true });
    const expandAll = () => {
        let _expandedKeys = {};

        for (let node of nodesInProject) {
            expandNode(node, _expandedKeys);
        }

        setExpandedKeysEdit(_expandedKeys);
    };

    const collapseAll = () => {
        setExpandedKeysEdit({});
    };

    const expandNode = (node, _expandedKeys) => {
        if (node.children && node.children.length) {
            _expandedKeys[node.key] = true;

            for (let child of node.children) {
                expandNode(child, _expandedKeys);
            }
        }
    };

    useEffect(() => {
        if (onCreateProject && onCreateProject.id && !isInDatabase) {
            setProject(onCreateProject);
            setIsIOnDatabase(true);
            dispatch(getBankAptitudesOnCreation())
            dispatch(getCollabsNames())
            dispatch(getProjectMembersAndManagers({ id_project: onCreateProject.id }))
            dispatch(getProjectAptitudes({ id_project: onCreateProject.id }))
            if (mode === 'edit') {
                setActiveIndex(5)
                dispatch(getProjectProgress({ id_project: onCreateProject.id, session: onCreateProject.last_session_number }))
            }
            setValidFields({
                nom: true,
                start_date: true,
                tried: false,
            })
        }
    }, [onCreateProject]);

    useEffect(() => {
        if (respProjectSaved != null) {
            if (respProjectSaved.trim().toLowerCase().includes("successfully")) {
                dispatch(getProjects())
                showSuccessToast(respProjectSaved);
                if (mode != 'edit') {
                    setActiveIndex(activeIndex >= 4 ? 0 : activeIndex + 1);
                }
            } else {
                showErrorToast(respProjectSaved);
            }
            dispatch(setRespProjectSaved(null));
        }
    }, [respProjectSaved]);

    useEffect(() => {
        if (respProjectDefForm != null) {
            if (respProjectDefForm.trim().toLowerCase().includes("successfully")) {
                if (onCreateProject) {
                    dispatch(getProjectAptitudes({ id_project: onCreateProject.id }))
                }
                showSuccessToast(respProjectDefForm);
                if (mode != 'edit') {
                    setActiveIndex(activeIndex >= 4 ? 0 : activeIndex + 1);
                }
            } else {
                showErrorToast(respProjectDefForm);
            }
            dispatch(setRespProjectDefForm(null));
        }
    }, [respProjectDefForm]);

    useEffect(() => {
        if (respProjectElement != null) {
            if (respProjectElement.trim().toLowerCase().includes("successfully")) {
                dispatch(getBankAptitudesOnCreation())
                if (onCreateProject) {
                    dispatch(getProjectAptitudes({ id_project: onCreateProject.id }))
                }
                showSuccessToast(respProjectElement);
            } else if (respProjectElement.trim().toLowerCase().includes("removed")) {
                if (onCreateProject) {
                    dispatch(getProjectAptitudes({ id_project: onCreateProject.id }))
                }
                showSuccessToast(respProjectElement);
            } else {
                showErrorToast(respProjectElement);
            }
            dispatch(setRespProjectElement(null));
        }
    }, [respProjectElement]);

    useEffect(() => {
        if (respProjectDelete != null) {
            if (respProjectDelete.trim().toLowerCase().includes("successfully")) {
                showErrorToast(respProjectDelete);
                dispatch(getProjects())
            } else {
                showErrorToast(respProjectDelete);
            }
            dispatch(setRespProjectDelete(null));
        }
    }, [respProjectDelete]);

    useEffect(() => {
        if (respProjectMembers != null) {
            if (respProjectMembers.trim().toLowerCase().includes("successfully")) {
                showSuccessToast(respProjectMembers);
                dispatch(getProjectMembersAndManagers({ id_project: onCreateProject.id }))
            } else {
                showErrorToast(respProjectMembers);
            }
            dispatch(setRespProjectMembers(null));
        }
    }, [respProjectMembers]);

    useEffect(() => {
        if (bankAptitudesOnCreation.categories?.length > 0) {
            const excludedKeys = excludedKey;
            const processNode = (node) => {
                const keyPrefix = node.scope === 'category' ? 'cat' :
                    node.scope === 'subcategory' ? 'subcat' : 'apt';
                const key = `${keyPrefix}-${node.id}`;
                let processedChildren = node.children ? node.children.map(processNode).filter(child => child !== undefined) : [];
                if ((keyPrefix === 'cat' || keyPrefix === 'subcat') && processedChildren.length === 0) {
                    return undefined;
                }
                if (!excludedKeys.includes(key)) {
                    return {
                        key: key,
                        id: node.id,
                        label: node.nom,
                        coef: node.coef,
                        className: node.scope,
                        pos: node.pos,
                        visibility: node.visibility,
                        description: node.description,
                        type: node.type,
                        aspect: node.aspect ? node.aspect : null,
                        children: node.children ? node.children.map(child => processNode(child)).filter(child => child !== undefined) : [],
                    };
                }
            };
            const sdata = bankAptitudesOnCreation.categories.map(category =>
                processNode(category)
            );
            setNodes(sdata.filter(s => s !== undefined));
        }
    }, [bankAptitudesOnCreation, addedApt, excludedKey]);

    useEffect(() => {
        let data = [];
        let popedKeys = [];

        if (projectAptitudes && projectAptitudes.categories) {
            const processNode = (node) => {
                const keyPrefix = node.scope === 'category' ? 'cat' :
                    node.scope === 'subcategory' ? 'subcat' : 'apt';
                const key = `${keyPrefix}-${node.id}`;

                if (keyPrefix === 'apt') {
                    popedKeys.push(key);
                }

                let processedNode = {
                    key: key,
                    id: node.id,
                    label: node.nom,
                    coef: node.coef,
                    className: node.scope,
                    pos: node.pos,
                    visibility: node.visibility,
                    description: node.description,
                    type: node.type,
                    aspect: node.aspect ? node.aspect : null,
                    children: node.children ? node.children.map(child => processNode(child)) : [],
                    active_mcq: node.active_mcq
                };

                // Ajouter les questions si le noeud est une aptitude
                if (node.scope === 'aptitude' && node.questions) {
                    processedNode.questions = node.questions.map(question => ({
                        id: question.id,
                        id_aptitude: question.id_aptitude,
                        lang: question.lang,
                        question: question.question,
                        type: question.type,
                        pos: question.pos,
                        correct_answers: question.correct_answers,
                        created_at: question.created_at,
                        updated_at: question.updated_at,
                        created_by: question.created_by,
                        updated_by: question.updated_by,
                        options: question.options ? question.options.map(option => ({
                            id: option.id,
                            id_question: option.id_question,
                            value: option.value,
                            pos: option.pos,
                            created_at: option.created_at,
                            updated_at: option.updated_at,
                            created_by: option.created_by,
                            updated_by: option.updated_by
                        })) : [],
                        active_mcq: node.active_mcq
                    }));
                }

                return processedNode;
            };

            data = projectAptitudes.categories.map(category =>
                processNode(category)
            );
        }

        setNodesInProject(data);
        setExcludedKey(popedKeys);
    }, [projectAptitudes]);


    useEffect(() => {
        let IdCollabs = collabsIds.map(collab => collab.id_collab)
        let collabsListed = collabsNames.filter(collab => IdCollabs.includes(collab.id))
        collabsListed = collabsListed.map(collab => {
            let sc = collabsIds.find(c => c.id_collab == collab.id)
            return {
                ...collab,
                active_mcq: parseInt(sc.active_mcq),
                latest_mcq_response: sc.latest_mcq_response,
                last_responses_number: sc.last_responses_number,
                last_response_date: sc.last_response_date
            }
        })
        setCollabsList(collabsListed);
        setMangersList(collabsNames.filter(collab => managersIds.includes(collab.id)))
    }, [managersIds, collabsIds])

    useEffect(() => {
        if (activeIndex == 2) {
            dispatch(getProjectAptitudes({ id_project: onCreateProject.id }))
        }
        if (activeIndex == 3 || activeIndex == 4) {
            dispatch(getProjectMembersAndManagers({ id_project: onCreateProject.id }))
        }
        if (activeIndex == 5) {
            if (mode === 'edit') {
                dispatch(getProjectMembersAndManagers({ id_project: onCreateProject.id }))
                dispatch(getProjectProgress({ id_project: onCreateProject.id, session: onCreateProject.last_session_number }))
            }
        }
    }, [activeIndex])

    const handleClose = () => {
        setProject({
            nom: '',
            start_date: null,
            end_date: null,
            days_limit: 1,
            active: true,
            image_src: null
        });
        setValidFields({
            nom: false,
            start_date: false,
            tried: false,
        });
        setActiveIndex(0);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        dispatch(setCreateProjectModalVisible(false));
        dispatch(setOnCreateProject(null));
        dispatch(setProjectMode(null));
        dispatch(setProjectProgress(0));
        dispatch(setProjectProgressDetails([]))
        dispatch(setProjectMembers([]))
        dispatch(setProjectManagers([]))
        setIsIOnDatabase(false);
        setAddedApt([])
        setExcludedKey([])
        setSelectedKeys({})
        dispatch(setAddedAptitudes([]))
        dispatch(setProjectAptitudes([]))
        setNodes([]);
        setNodesInProject([])
        dispatch(getProjects())
        dispatch(setSessionEndDate(null))
        dispatch(setSessionStartDate(null))
    };

    const handleProjectNameChange = (e) => {
        if (e.target.value.trim() !== '' && e.target.value != null) {
            //valid
            setValidFields({
                ...validFields,
                nom: true
            });
        } else {
            setValidFields({
                ...validFields,
                nom: false
            });
        }
        setProject({
            ...project,
            nom: e.target.value
        });
    };

    const handleProjectDateChangeStart = (e) => {
        if (e.value != '' && e.value != null) {
            setValidFields({
                ...validFields,
                start_date: true
            });
        } else {
            setValidFields({
                ...validFields,
                start_date: false
            });
        }
        setProject({
            ...project,
            start_date: e.value
        });
        if (project.end_date != null) {
            if (project.end_date < e.value) {
                setProject({
                    ...project,
                    end_date: e.value
                });
            }
        }
    };

    const handleProjectDateChangeEnd = (e) => {
        setProject({
            ...project,
            end_date: e.value
        });
    };

    const handleProjectActive = (param) => {
        setProject({
            ...project,
            active: param //=== 'true'
        });
    };

    const handleProjectActiveImageButton = () => {
        setProject({
            ...project,
            active: !project.active
        });
    };

    //handling image
    const handleImageChange = (e) => {
        if (e.target.files[0].size > 65530) {
            alert('File is too large, please upload a file less than 65KB.');
            return;
        }
        processFile(e.target.files[0]);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = () => {
        setProject({ ...project, image_src: null });
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const processFile = (file) => {
        if (file && file.type.startsWith('image')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProject({
                    ...project,
                    image_src: reader.result
                });
            };
            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file.size > 65530) {
            alert('File is too large, please upload a file less than 65KB.');
            return;
        }
        processFile(file);
    };

    const handleProjectDelay = (e) => {
        setProject({
            ...project,
            days_limit: e.value >= 0 ? e.value : 0
        });
    };

    const confirm1 = () => {
        if (mode != "edit") {
            if (isInDatabase) {
                confirmDialog({
                    message: <Langs str="CONFIRM_PROJECT_CANCEL" />,
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    defaultFocus: 'accept',
                    draggable: false,
                    acceptLabel: <Langs str="SAVE" />,
                    rejectLabel: <Langs str="DISCARD" />,
                    accept: saveAfterCancel,
                    reject: deleteAfterCancel,
                });
            } else {
                handleClose();
            }
        } else {
            handleClose();
        }
    };
    const confirmDeleteCatSub = (id) => {
        confirmDialog({
            message: <Langs str="DELETE_ASSOCIATED" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="KEEP" />,
            rejectLabel: <Langs str="DELETE" />,
            accept: () => { deleteCatSubFromProject(true, id) },
            reject: () => { deleteCatSubFromProject(false, id) },
        });
    };
    const confirmDeleteCollab = (id) => {
        confirmDialog({
            message: <Langs str="REMOVE_COLLAB_FROM_PROJECT" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="REMOVE" />,
            rejectLabel: <Langs str="CANCEL" />,
            accept: () => { dispatch(addRemoveProjectCollabsManagers({ id_project: onCreateProject.id, removed_collabs: [id] })) },
        });
    };
    const confirmDeleteManager = (id) => {
        confirmDialog({
            message: <Langs str="REMOVE_MANAGER_FROM_PROJECT" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="REMOVE" />,
            rejectLabel: <Langs str="CANCEL" />,
            accept: () => { dispatch(addRemoveProjectCollabsManagers({ id_project: onCreateProject.id, removed_managers: [id] })) },
        });
    };

    const deleteCatSubFromProject = (keeper, id) => {
        dispatch(deleteProjectCatSub(
            {
                keep: keeper,
                id_project: onCreateProject?.id,
                id_category: id
            }
        ))
    }

    const confirmDeleteApt = (id) => {
        confirmDialog({
            message: <Langs str="DELETE_APTITUDE_CONFIRM" />,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            draggable: false,
            acceptLabel: <Langs str="DELETE" />,
            rejectLabel: <Langs str="CANCEL" />,
            accept: () => { deleteAptFromProject(id) }
        });
    };

    const deleteAptFromProject = (id) => {
        dispatch(deleteProjectAptitude(
            {
                id_project: onCreateProject?.id,
                id_aptitude: id
            }
        ))
    }

    const footerTemplate = (data) => {
        return (
            <React.Fragment>
                <td colSpan={100}>
                    <div className="d-flex justify-content-start fw-bold w-100">
                        <Langs str={"Total"} /> : {Array.isArray(data) && data.length}
                    </div>
                </td>
            </React.Fragment>
        )
    }

    const saveAfterCancel = () => {
        handleClose();
    };

    const deleteAfterCancel = () => {
        dispatch(deleteProject({ id_project: project.id }));
        setSelectedKeys({});
        handleClose();
    };

    //datelist functions
    function handleDateListSpan(sd, ed, d) {
        if (sd == null || d == null) {
            setDatesList([]);
        } else {
            let startDate = moment(sd, "DD/MM/YYYY");
            let endDate = ed ? moment(ed, "DD/MM/YYYY") : moment(sd, "DD/MM/YYYY").add(2, 'y');
            let dates = [];
            let currentDate = startDate.clone();
            while (currentDate <= endDate) {
                dates.push(currentDate.format("DD/MM/YYYY"));
                currentDate.add(d, 'days');
            }
            setDatesList(dates);
        }
    }

    const handleProjectDetailsSave = () => {
        if (!validFields.nom || !validFields.start_date) {
            setValidFields({
                ...validFields,
                tried: true
            });
        } else {
            setValidFields({
                ...validFields,
                tried: false
            });
        }
        if (validFields.nom && validFields.start_date) {
            dispatch(setOnCreateProject(project));
            dispatch(addEditVcompProject({
                ...project,
                start_date: moment(project.start_date).format("DD/MM/YYYY"),
                end_date: project.end_date ? moment(project.end_date).format("DD/MM/YYYY") : null,
                days_limit: project.days_limit * 7
            }));
        }
    };


    const handleAddDefaultProjectForm = () => {
        console.log(selectedKeys)
        if (selectedKeys) {
            let aptKeys = Object.keys(selectedKeys).filter(key => key);
            if (aptKeys.length > 0) {
                const aptitudes = aptKeys.map(aptitude => {
                    if (aptitude.split('-')[0] === 'apt') {
                        return parseInt(aptitude.split('-')[1])
                    }
                }).filter(aptitude => aptitude != null);
                const id_project = onCreateProject.id;
                const result = {
                    id_project,
                    aptitudes
                };
                setAddedApt(aptKeys)
                setExcludedKey(aptKeys)
                if (aptitudes.length > 0) {
                    dispatch(addDefaultProjectForm(result))
                }
                setSelectedKeys([])
            } else {
                setActiveIndex(activeIndex >= 2 ? 0 : activeIndex + 1);
            }
        } else {
            setActiveIndex(activeIndex >= 2 ? 0 : activeIndex + 1);
        }
    };

    //algorithm for allowing dynamic playing
    function isChild(objects, searchKey) {
        function searchChildren(children) {
            return children.some(child => {
                if (child.key === searchKey) {
                    return true;
                }
                if (child.children && child.children.length > 0) {
                    return searchChildren(child.children);
                }
                return false;
            });
        }
        return objects.some(obj => {
            if (obj.children && obj.children.length > 0) {
                return searchChildren(obj.children);
            }
            return false;
        });
    }
    const dragDropAptitudes = (event) => {
        const { value, dragNode, dropNode } = event;
        if (dropNode) {
            if (dropNode.key.trim().toLowerCase().includes("apt")) {
                return
            }
        }
        const hasCategoryChild = dragNode.children && dragNode.children.some(child => child.key.trim().toLowerCase().includes("cat"));
        if (hasCategoryChild && dropNode) {
            return
        }
        if (dropNode && isChild(value, dropNode.key) &&
            dropNode.key.trim().toLowerCase().includes("cat") &&
            !dragNode.key.trim().toLowerCase().includes("apt")) {
            return
        }
        setNodesInProject(value);
    }

    const handleAddtProjectFormEdit = () => {
        const categories = [];
        const aptitudes = []
        console.log(nodesInProject)
        nodesInProject.forEach((node, index) => {
            if (node.key.trim().toLowerCase().includes("cat")) {
                let idParent = node.id
                categories.push(
                    {
                        id: node.id,
                        pos: index
                    }
                )
                if (node.children && node.children.length > 0) {
                    node.children.forEach((childCat, index) => {
                        if (childCat.key.trim().toLowerCase().includes("cat")) {
                            categories.push(
                                {
                                    id: childCat.id,
                                    id_parent: idParent,
                                    pos: index
                                }
                            )
                            if (childCat.children && childCat.children.length > 0) {
                                childCat.children.forEach((childApt, index) => {
                                    if (childApt.key.trim().toLowerCase().includes("apt")) {
                                        aptitudes.push({
                                            id: childApt.id,
                                            id_category: childCat.id,
                                            pos: index
                                        })
                                    }
                                })
                            }
                        }
                        if (childCat.key.trim().toLowerCase().includes("apt")) {
                            aptitudes.push(
                                {
                                    id: childCat.id,
                                    id_category: idParent,
                                    pos: index
                                }
                            )
                        }
                    })
                }
            }
            if (node.key.trim().toLowerCase().includes("apt")) {
                aptitudes.push(
                    {
                        id: node.id,
                        id_category: null,
                        pos: index

                    }
                )
            }

        })
        if (onCreateProject && onCreateProject.id) {
            dispatch(reorderProjectForm({
                id_project: onCreateProject.id,
                categories: categories,
                aptitudes: aptitudes
            }))
        }
    }
    const handleNodeDoubleClick = (e) => {
        if (e.node.visibility == 1 || e.node.className === 'aptitude') {
            let subcat = isChild(nodesInProject, e.node.key);
            showAddElementModalForElement(e.node, subcat)
        }
    }

    function showAddElementModalForElement(element, subcat) {
        dispatch(setAddElementModalVisibility({ visibility: true, element: element, subcategory: subcat, isEdit: 1 }))
        dispatch(setSelectedAptitudeForQuestion(element))
    }

    const nodeTemplate = (node) => {
        let subcat = isChild(nodesInProject, node.key);
        let type = node ? node.key.split('-')[0] : null;

        const hasQuestions = node.questions && node.questions.length > 0;

        return (
            <div className="node-container">
                <span>{node.label}</span>&nbsp;
                {node.aspect === 'theoretical' && <b>[T]</b>}
                {node.aspect === 'practical' && <b>[P]</b>}
                {hasQuestions && <b><span style={{ color: '#FF4500' }}>[Q]</span></b>}
                {node.visibility === 2 && <i className="pi pi-lock"></i>}
                <div className="node-button">
                    <Row>
                        {isMobile === 1 && node.visibility == 1 && (
                            <ImageButton
                                image={editIcon}
                                imageFocus={editIconFocus}
                                onClick={() => {
                                    showAddElementModalForElement(node, subcat);
                                }}
                                active={true}
                                width={32}
                            />
                        )}
                        {isMobile === 1 && (
                            <ImageButton
                                image={deleteIcon}
                                imageFocus={deleteIconFocus}
                                onClick={() => {
                                    if (type === "apt") {
                                        confirmDeleteApt(node.id);
                                    } else {
                                        confirmDeleteCatSub(node.id);
                                    }
                                }}
                                active={true}
                                width={32}
                            />
                        )}
                        {node.className !== 'aptitude' && (
                            <ImageButton
                                image={addIcon}
                                imageFocus={addIconFocus}
                                onClick={() => {
                                    dispatch(setAddElementModalVisibility({
                                        visibility: true,
                                        element: node,
                                        subcategory: subcat,
                                        isEdit: 0
                                    }));
                                }}
                                active={true}
                                width={32}
                            />
                        )}
                    </Row>
                </div>
            </div>
        );
    };

    const nodeTemplatePublic = (node) => {
        const hasQuestions = node.questions && node.questions.length > 0;
        return (
            <div className="node-container">
                <span>{node.label}</span>&nbsp;{node.aspect === 'theoretical' && <b>[T]</b>}{node.aspect === 'practical' && <b>[P]</b>}&nbsp;{hasQuestions && <b><span style={{ color: '#FF4500' }}>[Q]</span></b>}
            </div>
        );
    }

    const editTreeHeader = () => {
        let position;
        if (projectAptitudes) {
            position = projectAptitudes.categories?.length
        } else {
            position = 0
        }
        return (
            <div className="">
                <ImageButton
                    image={excelIcon}
                    imageFocus={excelIconFocus}
                    onClick={() => {
                        setExcelModalVisibility(true)
                    }}
                    active={true}
                    width={32}
                />
                <ImageButton
                    image={addIcon}
                    imageFocus={addIconFocus}
                    onClick={() => {
                        dispatch(setAddElementModalVisibility({ visibility: true, element: null, subcategory: false, pos: position }))
                    }}
                    active={true}
                    width={32}
                />
            </div>
        )
    }

    const leftMenu = (index) => {
        const isClickable = project.nom != "";

        return (
            <ul className={styles.menuLeft}>
                {mode === 'edit' &&
                    <li
                        onClick={() => isClickable && isInDatabase && setActiveIndex(5)}
                        className={`${index === 5 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                    >
                        <Langs str="PROGRESS" />
                    </li>
                }
                <li
                    onClick={() => isClickable && setActiveIndex(0)}
                    className={`${index === 0 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                >
                    {isMobile === 2 &&
                        <ImageButton
                            onClick={() => isClickable && confirm1()}
                            image={prevTab}
                            imageFocus={prevTabFocus}
                            active={isClickable}
                            width={22} />}
                    <span className="mx-4"><Langs str="PROJECT_DETAILS" /></span>
                    {isMobile === 2 && <ImageButton
                        onClick={() => isClickable && handleProjectDetailsSave()}
                        image={nextTab}
                        imageFocus={nextTabFocus}
                        active={isClickable}
                        width={22} />}
                </li>
                <li
                    onClick={() => isClickable && setActiveIndex(1)}
                    className={`${index === 1 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                >
                    <Langs str="PUBLIC_FORMULAIRE" />
                </li>
                <li
                    onClick={() => isClickable && setActiveIndex(2)}
                    className={`${index === 2 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                >
                    <Langs str="EDIT_FORM" /> & <Langs str="MCQ" />
                </li>
                <li
                    onClick={() => isClickable && isInDatabase && setActiveIndex(3)}
                    className={`${index === 3 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                >
                    <Langs str="MEMBERS" /> & <Langs str="MCQ" />
                </li>
                <li
                    onClick={() => isClickable && isInDatabase && setActiveIndex(4)}
                    className={`${index === 4 ? styles.selected : ''} ${!isClickable ? styles.disabled : ''}`}
                >
                    <Langs str="MANAGERS" />
                </li>
            </ul>
        );
    };

    const mobileMenuBar = () => {
        const onTabChange = (e) => {
            setActiveIndex(e.index);
        }
        return (
            <div className="card">
                <TabView className="customTabView" activeIndex={activeIndex} onTabChange={onTabChange}>
                    <TabPanel header="General">
                    </TabPanel>
                    <TabPanel header={<Langs str="PUBLIC_FORMULAIRE" />} disabled={!isInDatabase}>
                    </TabPanel>
                    <TabPanel header={<Langs str="EDIT_FORM" />} disabled={!isInDatabase}>
                    </TabPanel>
                    <TabPanel header={<Langs str="MEMBERS" />} disabled={!isInDatabase}>
                    </TabPanel>
                    <TabPanel header={<Langs str="MANAGERS" />} disabled={!isInDatabase}>
                    </TabPanel>
                    <TabPanel header={<Langs str="PROGRESS" />} disabled={!isInDatabase}>
                    </TabPanel>
                </TabView>
            </div>
        )
    }

    const removeCollabBtn = (rowData) => {
        return (
            <ImageButton
                image={deleteIcon}
                imageFocus={deleteIconFocus}
                onClick={() => {
                    confirmDeleteCollab(rowData.id)
                }}
                active={true}
                width={32}
            />
        )
    }
    const handleActivateCollabMcq = (e, rowData) => {

        if (e.value === true) {
            setCollabsList(
                collabsList.map(collab => {
                    if (collab.id === rowData.id) {
                        return {
                            ...collab,
                            active_mcq: 1,
                        }
                    }
                    return collab
                })
            )
            if (onCreateProject && onCreateProject.id) {
                dispatch(activateMcqCollabs({ id_project: onCreateProject.id, activate: [rowData.id] }))
            }
        }
        if (e.value === false) {
            setCollabsList(
                collabsList.map(collab => {
                    if (collab.id === rowData.id) {
                        return {
                            ...collab,
                            active_mcq: 0,
                        }
                    }
                    return collab
                })
            )
            if (onCreateProject && onCreateProject.id) {
                dispatch(activateMcqCollabs({ id_project: onCreateProject.id, deactivate: [rowData.id] }))
            }
        }
    }
    const toggleActiveMcq = (rowData) => {
        return (
            <div className="d-flex justify-content-center">
                <InputSwitch
                    checked={rowData.active_mcq == 1}
                    onChange={(e) => { handleActivateCollabMcq(e, rowData); }}
                />
            </div>
        )
    }
    const handleActivateAllMcq = (e) => {
        const isActive = e.value === true ? 1 : 0;

        setCollabsList(
            collabsList.map(collab => ({
                ...collab,
                active_mcq: isActive
            }))
        );

        if (onCreateProject && onCreateProject.id) {
            const ids = collabsList.map(collab => collab.id);
            if (isActive === 1) {
                dispatch(activateMcqCollabs({ id_project: onCreateProject.id, activate: ids }));
            } else {
                dispatch(activateMcqCollabs({ id_project: onCreateProject.id, deactivate: ids }));
            }
        }
    };

    const toggleAllMcq = () => {
        const allActive = collabsList.every(collab => collab.active_mcq === 1);

        return (
            <InputSwitch
                checked={allActive}
                onChange={handleActivateAllMcq}
            />
        );
    };
    const viewMcqResultsModal = (id, lrn, lrd) => {
        dispatch(setCollabMcqResultsModalVisibility(true))
        dispatch(setCollabIdMcqResults(id))
        dispatch(setLastResponseNumber({ response_number: lrn, created_at: lrd }))
    }
    const mcqResultsCol = (rowData) => {
        // <div style={{ width: "40%" }} className="d-flex justify-content-center">
        //     <ImageButton
        //         image={mcqResults}
        //         imageFocus={mcqResultsFocus}
        //         onClick={() => viewMcqResultsModal(rowData.id)}
        //         active={true}
        //         width={32}
        //     />
        // </div>
        if (rowData.last_response_date) {
            return (
                <p
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'blue'
                    }}
                    onClick={() => viewMcqResultsModal(rowData.id, rowData.last_responses_number, rowData.last_response_date)}>
                    {moment(rowData.last_response_date).format('DD/MM/YYYY')}
                </p>
            )
        }
        return <p>Pas de réponse</p>
    }
    const removeManagerBtn = (rowData) => {
        if (mangersList.length > 1) {
            return (
                <ImageButton
                    image={deleteIcon}
                    imageFocus={deleteIconFocus}
                    onClick={() => {
                        confirmDeleteManager(rowData.id)
                    }}
                    active={true}
                    width={32}
                />
            )
        }
    }

    const addCollabBtn = () => {
        return (
            <ImageButton
                image={addIcon}
                imageFocus={addIconFocus}
                onClick={() => {
                    dispatch(setAddMembersModalVisibility(true))
                }}
                active={true}
                width={32}
            />
        )
    }

    const addManagerBtn = () => {
        return (
            <ImageButton
                image={addIcon}
                imageFocus={addIconFocus}
                onClick={() => {
                    dispatch(setAddMembersModalVisibility(true));
                    dispatch(setAddMembersMode("managers"))
                }}
                active={true}
                width={32}
            />
        )
    }

    const rightView = (index) => {
        switch (index) {
            case 0:
                return (
                    <div>
                        <div className={`card p-3 m-4 d-flex justify-content-center align-content-center border-0`}>
                            <Row className="p-2 m-3">
                                <Col lg={12} className="d-flex justify-content-center" style={{ height: "200px" }}>
                                    <div className={styles.imageUploadContainer}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        onClick={() => !project.image_src && handleImageClick()}
                                    >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            ref={fileInputRef}
                                            style={{ display: 'none' }} />
                                        <div className={styles.imageContainer}>
                                            {project.image_src &&
                                                <img
                                                    src={project.image_src ? project.image_src : addImageIcon}
                                                    alt="Click to upload"
                                                    onClick={handleImageClick}
                                                    className={project.image_src ? styles.addImageBlock : styles.addImageBlockIcon} />}
                                            {!project.image_src && isMobile !== 2 &&
                                                <span className={styles.spanImageContainer}>
                                                    <Langs str='DRAG_DROP_IMAGE' />
                                                </span>}
                                            {!project.image_src && isMobile === 2 &&
                                                <span className={styles.spanImageContainer}>
                                                    <Langs str='ADD_IMAGE' />
                                                </span>}
                                            {project.image_src && (
                                                <div className={styles.deleteButton}>
                                                    <ImageButton
                                                        onClick={handleDeleteImage}
                                                        image={deleteImageIcone}
                                                        imageFocus={deleteImageIcone}
                                                        active={true}
                                                        width={38} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2 m-3">
                                <Col lg={12}>
                                    <FloatLabel>
                                        <InputText invalid={!validFields.nom && validFields.tried}
                                            maxLength={250}
                                            style={{ width: "100%" }}
                                            id="project-name"
                                            value={project.nom}
                                            onChange={(e) => handleProjectNameChange(e)} />
                                        <label htmlFor="project-name"><Langs str='PROJECT_NAME' /></label>
                                    </FloatLabel>
                                </Col>
                            </Row>
                            <Row className="p-2 m-3">
                                <Col lg={6} className="d-flex justify-content-start">
                                    <FloatLabel>
                                        <Calendar dateFormat="dd/mm/yy" invalid={!validFields.start_date && validFields.tried}
                                            id="project-date"
                                            value={project.start_date}
                                            disabled={mode == 'edit'}
                                            onChange={(e) => handleProjectDateChangeStart(e)} />
                                        <label htmlFor="project-date"><Langs str='START_DATE' /></label>
                                    </FloatLabel>
                                </Col>
                                <Col lg={6} className={isMobile == 2 ? " mt-3" : "d-flex justify-content-end"}>
                                    <FloatLabel>
                                        <Calendar minDate={project.start_date} disabled={!project.start_date} dateFormat="dd/mm/yy" id="project-date" value={project.end_date} onChange={(e) => handleProjectDateChangeEnd(e)} />
                                        <label htmlFor="project-date"><Langs str='END_DATE' /></label>
                                    </FloatLabel>
                                </Col>
                            </Row>
                            <Row className="p-2 m-3">
                                <Col lg={6} className="d-flex justify-content-start">
                                    <FloatLabel>
                                        <InputNumber
                                            inputId="project-delay"
                                            value={project.days_limit}
                                            onValueChange={(e) => handleProjectDelay(e)}
                                            showButtons
                                            buttonLayout="horizontal"
                                            step={1}
                                            min={1}
                                            allowEmpty={false}
                                            inputStyle={{ width: "6rem", textAlign: "center" }}
                                            decrementButtonClassName={`p-button-warning `}
                                            incrementButtonClassName="p-button-warning"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" />
                                        <label htmlFor="project-delay"><Langs str='DELAY_WEEKS' /><Langs />
                                        </label>
                                    </FloatLabel>
                                </Col>
                                {isMobile === 1 && (
                                    <Col lg={6} className="d-flex justify-content-end">
                                    </Col>
                                )}
                            </Row>
                            <Row className="p-2 m-3 justify-content-end align-items-center">
                                <Col xs={9}>
                                    <Langs str="PROJECT_STATUS" />
                                </Col>
                                <Col xs={3}>
                                    <ToggleButton checked={project.active == true} className="w-100"
                                        onLabel={Localize("Active")}
                                        offLabel={Localize("Inactive")}
                                        tooltip={project.active ? Localize("Deactivate") : Localize("Activate")}
                                        onChange={(e) => { handleProjectActive(e.value) }} />
                                </Col>
                            </Row>
                            {isMobile === 2 && (
                                <Row className="mt-3 d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Col xs={6} className="d-flex justify-content-center">
                                        <ThemeButton title={mode == "edit" ? <Langs str="SAVE" /> : <Langs str="Next" />} active={true} onClick={handleProjectDetailsSave} style={{ width: '48%' }} />
                                    </Col>
                                </Row>

                            )}
                        </div>
                        {isMobile !== 2 && (
                            <div>
                                <div style={{
                                    position: 'fixed',
                                    bottom: '1.5rem',
                                    right: isMobile === 3 ? '3rem' : '1.5rem',
                                    width: isMobile === 3 ? '15%' : '7%'
                                }}>
                                    <ThemeButton
                                        title={mode === "edit" ? <Langs str="SAVE" /> : <Langs str="Next" />}
                                        active={true}
                                        onClick={handleProjectDetailsSave}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                );

            case 1:
                return (
                    <div>
                        <div
                            className={`card p-3 m-4 d-flex justify-content-center align-content-center border-0`}
                            style={isMobile === 2 ? { height: 'calc(100vh - 150px)', display: 'flex', flexDirection: 'column' } : {}}
                        >
                            <div style={{ flex: 1, overflowY: 'auto' }}>
                                <div style={{ height: "calc(100vh - 250px)" }}>
                                    <label className='d-flex justify-content-center'>
                                        <Langs str="ADD_PUBLIC_APTITUDES" />
                                    </label>
                                    <Tree
                                        value={nodes}
                                        selectionMode="checkbox"
                                        selectionKeys={selectedKeys}
                                        filter={true}
                                        nodeTemplate={nodeTemplatePublic}
                                        filterPlaceholder="Search..."
                                        onSelectionChange={(e) => setSelectedKeys(e.value)}
                                        className="w-full md:w-30rem custom-tree border-0"
                                    />
                                </div>
                            </div>
                            {
                                isMobile === 2 && mode != "edit" && (
                                    <Row className="mt-3 d-flex justify-content-around" style={{ width: '100%' }}>
                                        <Col xs={5} className="d-flex justify-content-start">
                                            <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => setActiveIndex(0)} style={{ width: '48%' }} />
                                        </Col>
                                        <Col xs={5} className="d-flex justify-content-end">
                                            <ThemeButton title={<Langs str="Next" />} active={true} onClick={handleAddDefaultProjectForm} style={{ width: '48%' }} />
                                        </Col>
                                    </Row>
                                )
                            }
                            {
                                isMobile === 2 && mode == "edit" && (
                                    <Row className="mt-3 d-flex justify-content-center" style={{ width: '100%' }}>
                                        <Col xs={6} className="d-flex justify-content-center">
                                            <ThemeButton title={<Langs str="SAVE" />} active={true} onClick={handleAddDefaultProjectForm} style={{ width: '48%' }} />
                                        </Col>
                                    </Row>
                                )
                            }
                        </div>
                        {
                            isMobile !== 2 && mode != "edit" && (
                                <div>
                                    <div style={{ position: 'fixed', bottom: '1.5rem', left: '21rem', width: '7%' }}>
                                        <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => setActiveIndex(0)} style={{ width: '100%' }} />
                                    </div>
                                    <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: '7%' }}>
                                        <ThemeButton title={<Langs str="Next" />} active={true} onClick={handleAddDefaultProjectForm} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            isMobile !== 2 && mode === "edit" && (
                                <div>
                                    <div style={{
                                        position: 'fixed',
                                        bottom: '1.5rem',
                                        right: isMobile === 3 ? '3rem' : '1.5rem',
                                        width: isMobile === 3 ? '15%' : '7%'
                                    }}>
                                        <ThemeButton
                                            title={<Langs str="SAVE" />}
                                            active={true}
                                            onClick={handleAddDefaultProjectForm}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div
                            className={`card ${isMobile === 2 ? 'mt-5 d-flex justify-content-center align-content-center border-0' : 'p-3 m-4 d-flex justify-content-center align-content-center border-0'}`}
                            style={isMobile === 2 ? { height: '85%', display: 'flex', flexDirection: 'column' } : {}}
                        >
                            <Row>
                                <Col xs={4} className="d-flex p-1 ps-4 justify-content-start align-items-center">
                                    <Langs str="EDIT_PROJECT_FORM" />
                                </Col>
                                <Col xs={8} className={`d-flex justify-content-end ${isMobile === 2 ? '' : 'p-2'}`}>
                                    <Button
                                        className={`btn btn-secondary me-2 ${isMobile === 2 ? 'btn-sm' : ''}`}
                                        type="button"
                                        label={<Langs str="Expand All" />}
                                        onClick={expandAll}
                                    />
                                    <Button
                                        className={`btn btn-secondary me-2 ${isMobile === 2 ? 'btn-sm' : ''}`}
                                        type="button"
                                        label={<Langs str="Collapse All" />}
                                        onClick={collapseAll}
                                    />
                                    {editTreeHeader()}
                                </Col>
                            </Row>
                            <div style={{ flex: 1, overflowY: 'auto' }}>
                                <div style={{ height: isMobile === 2 ? "calc(100vh - 300px)" : "calc(100vh - 220px)" }}>
                                    <Tree
                                        value={nodesInProject}
                                        dragdropScope="demo"
                                        onNodeDoubleClick={(e) => { handleNodeDoubleClick(e) }}
                                        nodeTemplate={nodeTemplate}
                                        filter={true}
                                        filterPlaceholder="Search..."
                                        onDragDrop={(e) => { dragDropAptitudes(e) }}
                                        showHeader={true}
                                        className="w-full md:w-30rem custom-tree border-0"
                                        expandedKeys={expandedKeysEdit}
                                        onToggle={(e) => setExpandedKeysEdit(e.value)}
                                    />
                                </div>
                            </div>
                            {isMobile === 2 && mode != "edit" && (
                                <Row className="mt-3 d-flex justify-content-around" style={{ width: '100%' }}>
                                    <Col xs={5} className="d-flex justify-content-start">
                                        <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => setActiveIndex(1)} style={{ width: '28%' }} />
                                    </Col>
                                    <Col xs={5} className="d-flex justify-content-end">
                                        <ThemeButton title={<Langs str="Next" />} active={true} onClick={handleAddtProjectFormEdit} style={{ width: '48%' }} />
                                    </Col>
                                </Row>
                            )}
                            {isMobile === 2 && mode == "edit" && (
                                <Row className="mt-3 d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Col xs={6} className="d-flex justify-content-center">
                                        <ThemeButton title={<Langs str="SAVE" />} active={true} onClick={handleAddtProjectFormEdit} style={{ width: '48%' }} />
                                    </Col>
                                </Row>
                            )}
                        </div>
                        {isMobile !== 2 && mode != "edit" && (
                            <div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', left: '21rem', width: '7%' }}>
                                    <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => setActiveIndex(1)} style={{ width: '100%' }} />
                                </div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: '7%' }}>
                                    <ThemeButton title={<Langs str="Next" />} active={true} onClick={handleAddtProjectFormEdit} style={{ width: '100%' }} />
                                </div>
                            </div>
                        )}
                        {
                            isMobile !== 2 && mode === "edit" && (
                                <div>
                                    <div style={{
                                        position: 'fixed',
                                        bottom: '1.5rem',
                                        right: isMobile === 3 ? '3rem' : '1.5rem',
                                        width: isMobile === 3 ? '15%' : '7%'
                                    }}>
                                        <ThemeButton
                                            title={<Langs str="SAVE" />}
                                            active={true}
                                            onClick={handleAddtProjectFormEdit}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div
                            className={`card p-3 m-4 d-flex align-content-center border-0`}
                            style={isMobile === 2 ? { height: '85%', display: 'flex', flexDirection: 'column' } : {}}
                        >
                            <Row className='d-flex justify-content-between w-100 mb-4 mt-2 ps-2'>
                                <Col lg={4} xs={8} className="my-auto">
                                    <Langs str="EDIT_PROJECT_MEMBERS" />
                                </Col>
                                <Col lg={8} xs={4} className="d-flex justify-content-end pe-4 ">
                                    {addCollabBtn()}
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-between w-100 mb-4 mt-2 ps-2'>
                                <Langs str={"Total"} />: {collabsList.length}
                            </Row>
                            <DataTable ref={dtCollabs}
                                value={collabsList}
                                dataKey="id"
                                scrollable
                                style={{ height: isMobile === 2 ? "calc(90vh - 300px)" : "calc(100vh - 300px)" }}
                                scrollHeight={isMobile === 2 ? "calc(90vh - 300px)" : "calc(100vh - 300px)"}
                                stripedRows
                            >
                                <Column field="nom" header={<Langs str="nom" />}></Column>
                                <Column field="prenom" header={<Langs str="prenom" />} ></Column>
                                <Column
                                    body={toggleActiveMcq}
                                    style={{ maxWidth: '5rem' }}
                                    header={
                                        <div className={`d-flex ${isMobile === 2 ? 'flex-column' : 'align-items-center'}`}>
                                            <Langs str="MCQ" />
                                            <div className={` ${isMobile === 2 ? 'mt-1 justify-content-start' : 'ms-2 d-flex align-items-center justify-content-end'}`}>
                                                {toggleAllMcq()}
                                            </div>
                                        </div>
                                    }
                                    bodyClassName=""
                                />
                                <Column body={mcqResultsCol} header={<div><Langs str="MCQ" /> {" Resultas"}</div>} bodyClassName="" ></Column>
                                <Column body={removeCollabBtn} bodyClassName="d-flex justify-content-end pe-4" ></Column>
                            </DataTable>
                            {isMobile === 2 && mode != "edit" && (
                                <Row className="mt-3 d-flex justify-content-around" style={{ width: '100%' }}>
                                    <Col xs={5} className="d-flex justify-content-start">
                                        <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => { setActiveIndex(activeIndex - 1) }} style={{ width: '28%' }} />
                                    </Col>
                                    <Col xs={5} className="d-flex justify-content-end">
                                        <ThemeButton title="Next" active={true} onClick={() => { setActiveIndex(activeIndex + 1) }} style={{ width: '48%' }} />
                                    </Col>
                                </Row>

                            )}
                            {isMobile === 2 && mode == "edit" && (
                                <Row className="mt-3 d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Col xs={6} className="d-flex justify-content-center">
                                        <ThemeButton title={<Langs str="Next" />} active={true} onClick={() => { setActiveIndex(activeIndex + 1) }} style={{ width: '48%' }} />
                                    </Col>
                                </Row>)}
                        </div>
                        {isMobile !== 2 && mode != "edit" && (
                            <div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', left: '21rem', width: '7%' }}>
                                    <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => { setActiveIndex(activeIndex - 1) }} style={{ width: '100%' }} />
                                </div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: '7%' }}>
                                    <ThemeButton title={<Langs str="Next" />} active={true} onClick={() => { setActiveIndex(activeIndex + 1) }} style={{ width: '100%' }} />
                                </div>
                            </div>
                        )}
                        {
                            isMobile !== 2 && mode === "edit" && (
                                <div>
                                    <div style={{
                                        position: 'fixed',
                                        bottom: '1.5rem',
                                        right: isMobile === 3 ? '3rem' : '1.5rem',
                                        width: isMobile === 3 ? '15%' : '7%'
                                    }}>
                                        <ThemeButton
                                            title={<Langs str="Next" />}
                                            active={true}
                                            onClick={() => { setActiveIndex(activeIndex + 1) }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>

                );
            case 4:
                return (
                    <div>
                        <div
                            className={`card p-3 m-4 d-flex  align-content-center border-0`}
                            style={isMobile === 2 ? { height: '85%', display: 'flex', flexDirection: 'column' } : {}}
                        >
                            <Row className='d-flex justify-content-between align-items-center w-100 mb-4 mt-2 ps-2'>
                                <Col lg={4} xs={8} className="d-flex my-auto align-items-center">
                                    <Langs str="EDIT_PROJECT_MANAGERS" />
                                </Col>
                                <Col lg={4} xs={4} className="d-flex justify-content-end pe-4">
                                    {addManagerBtn()}
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-between w-100 mb-4 mt-2 ps-2'>
                                <Langs str={"Total"} />: {mangersList.length}
                            </Row>
                            <DataTable ref={dtManagers}
                                value={mangersList}
                                dataKey="id"
                                scrollable
                                style={{ height: isMobile === 2 ? "calc(90vh - 300px)" : "calc(100vh - 300px)" }}
                                scrollHeight={isMobile === 2 ? "calc(90vh - 300px)" : "calc(100vh - 300px)"}
                                selectionMode="single"
                                stripedRows
                                globalFilterFields={["nom", "prenom"]}
                            >
                                <Column field="nom" header={<Langs str="nom" />}></Column>
                                <Column field="prenom" header={<Langs str="prenom" />} ></Column>
                                <Column body={removeManagerBtn} bodyClassName="d-flex justify-content-end pe-4" ></Column>
                            </DataTable>
                            {isMobile === 2 && mode != "edit" && (
                                <Row className="mt-3 d-flex justify-content-around" style={{ width: '100%' }}>
                                    <Col xs={5} className="d-flex justify-content-start">
                                        <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => { setActiveIndex(activeIndex - 1) }} style={{ width: '28%' }} />
                                    </Col>
                                    <Col xs={5} className="d-flex justify-content-end">
                                        <ThemeButton title={<Langs str="Next" />} active={true} onClick={() => { handleClose() }} style={{ width: '120px' }} />
                                    </Col>
                                </Row>

                            )}
                            {isMobile === 2 && mode == "edit" && (
                                <Row className="mt-3 d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Col xs={8} className="d-flex justify-content-center">
                                        <ThemeButton title={<Langs str="Save & Exit" />} active={true} onClick={() => { handleClose() }} style={{ width: '48%' }} />
                                    </Col>
                                </Row>)}
                        </div>
                        {isMobile !== 2 && mode != "edit" && (
                            <div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', left: '21rem', width: isMobile === 3 ? '20%' : '7%' }}>
                                    <ThemeButton title={<Langs str="Previous" />} active={true} onClick={() => { setActiveIndex(activeIndex - 1) }} style={{ width: '100%' }} />
                                </div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: isMobile === 3 ? '20%' : '7%' }}>
                                    <ThemeButton title={<Langs str="Save & Exit" />} active={true} onClick={() => { handleClose() }} style={{ width: '100%' }} />
                                </div>
                            </div>
                        )}
                        {isMobile !== 2 && mode == "edit" && (
                            <div>
                                <div style={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', width: isMobile === 3 ? '20%' : '7%' }}>
                                    <ThemeButton title={<Langs str="Save & Exit" />} active={true} onClick={() => { handleClose() }} style={{ width: isMobile === 3 ? '20%' : '120px' }} />
                                </div>
                            </div>
                        )}

                    </div>
                );
            case 5:
                return (
                    <div>
                        <div
                            className={`card p-3 m-4 d-flex  align-content-center border-0`}
                            style={isMobile === 2 ? { height: 'calc(85vh - 300px)', display: 'flex', flexDirection: 'column' } : {}}
                        >
                            <ProgressView />
                            {isMobile === 2 && mode == "edit" && (
                                <Row className="mt-2 d-flex justify-content-center" style={{ width: '100%' }}>
                                    <Col xs={6} className="d-flex justify-content-center">
                                        <ThemeButton title={<Langs str="Next" />} active={true} onClick={() => { setActiveIndex(0) }} style={{ width: '48%' }} />
                                    </Col>
                                </Row>)}
                        </div>
                        {isMobile !== 2 && mode === "edit" && (
                            <div>
                                <div style={{
                                    position: 'fixed',
                                    bottom: '1.5rem',
                                    right: isMobile === 3 ? '3rem' : '1.5rem',
                                    width: isMobile === 3 ? '15%' : '7%'
                                }}>
                                    <ThemeButton
                                        title="Next"
                                        active={true}
                                        onClick={() => { setActiveIndex(0) }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                );
            default:
                return (
                    <div>
                        <h1>Welcome</h1>
                        <p>Please select an option from the left menu.</p>
                    </div>
                );
        }
    };

    function body() {
        return (
            <Row className={` ${styles.mainContainer}`}>
                <Col lg={2} className={` ${styles.leftContainer}`}>
                    <Row className="mt-4">
                        {leftMenu(activeIndex)}
                    </Row>
                </Col>
                {isMobile === 1 && <Col lg={1}></Col>}
                <Col lg={isMobile !== 1 ? 10 : 8} className={` ${styles.rightContainer}`}>
                    {isMobile !== 1 &&
                        mobileMenuBar()
                    }
                    {rightView(activeIndex)}
                </Col>
                <ConfirmDialog />
            </Row>
        );
    }


    const header = () => {
        return (
            <div>
                {isInDatabase && onCreateProject ? onCreateProject.nom : <Langs str="Create new Project" />}
            </div>
        );
    };

    const footer = () => {
        return;
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog
                visible={visible}
                style={{ width: '100vw', height: '100vh', maxHeight: "100%", backgroundColor: "grey" }}
                onHide={() => { isInDatabase ? confirm1() : handleClose(); }}
                draggable={false}
                resizable={false}
                header={header}
                footer={footer}
                className="customDialog"
            >
                {body()}
            </Dialog>
            <AddElementModal />
            <AddMembersModal />
            <McqResultsModal />
            <ExcelInputModal
                visibility={excelModalVisibility}
                setVisibility={(e) => { setExcelModalVisibility(e) }}
            />
        </div>
    );
}
