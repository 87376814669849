import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Row, Col } from 'react-bootstrap';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { showSuccessToast, showErrorToast } from "../../app/utils/helpers";
import { addEditQuestion, addEditAptitude, setAddQuestionModalVisibility, addEditQuestionAnswers, setCurrentQuestion, setSelectedAptitudeForQuestion, deleteQuestionAnswer } from './vcompSlice';

const AddQuestionsModal = () => {
    const dispatch = useDispatch();

    const element = useSelector((state) => state.vcomp.selectedAptitudeForQuestion);
    const currentQuestion = useSelector((state) => state.vcomp.currentQuestion);
    const onCreateProject = useSelector((state) => state.vcomp.onCreateProject);
    const questionDialogVisible = useSelector((state) => state.vcomp.addQuestionModalVisibility);

    const [questionNom, setQuestionNom] = useState(currentQuestion?.question || '');
    const [typeQuestions, setTypeQuestionsState] = useState(currentQuestion?.type || 'single');
    const [responses, setResponses] = useState(currentQuestion?.responses || []);

    const typesQuestionsOptions = [
        { label: 'Single', value: 'single' },
        { label: 'Multi', value: 'multi' }
    ];

    useEffect(() => {
        if (currentQuestion) {
            setQuestionNom(currentQuestion.question || '');
            setTypeQuestionsState(currentQuestion.type || 'single');

            const sortedResponses = (currentQuestion.options ? [...currentQuestion.options] : []).sort((a, b) => a.pos - b.pos);

            const responsesWithCorrectAnswers = sortedResponses.map((response) => ({
                ...response,
                isTrue: currentQuestion.correct_answers.includes(response.id)
            }));

            setResponses(responsesWithCorrectAnswers);
        } else {
            setQuestionNom('');
            setTypeQuestionsState('single');
            setResponses([]);
        }
    }, [currentQuestion]);

    const addResponse = () => {
        if (responses.length < 5) {
            setResponses([...responses, { value: '', isTrue: false }]);
        } else {
            showErrorToast("Vous ne pouvez pas ajouter plus de 5 réponses.");
        }
    };


    const updateResponse = (index, field, value) => {
        const updatedResponses = [...responses];

        if (field === 'isTrue' && typeQuestions === 'single') {
            updatedResponses.forEach((response, i) => {
                updatedResponses[i] = { ...response, isTrue: false };
            });
        }

        updatedResponses[index] = { ...updatedResponses[index], [field]: value };

        setResponses(updatedResponses);
    };


    const removeResponse = (index) => {
        const responseToDelete = responses[index];

        const updatedResponses = responses.filter((_, i) => i !== index);
        setResponses(updatedResponses);

        const updatedQuestions = element.questions.map((q) =>
            q.id === currentQuestion.id
                ? { ...currentQuestion, options: updatedResponses }
                : q
        );

        if (responseToDelete.id) {
            dispatch(deleteQuestionAnswer({ id: responseToDelete.id }));
            dispatch(addEditAptitude({
                ...element,
                questions: updatedQuestions
            }));
        }

        dispatch(setSelectedAptitudeForQuestion({
            ...element,
            questions: updatedQuestions
        }));
    };




    const handleSave = async () => {
        console.log(element)
        const questionData = {
            id_project: onCreateProject?.id || null,
            id: currentQuestion?.id || null,
            id_aptitude: element.id,
            lang: 'en',
            question: questionNom,
            type: typeQuestions,
            pos: currentQuestion?.pos || (element.questions.length + 1),
            correct_answers: []
        };
    
        const questionModified = currentQuestion
            ? JSON.stringify({
                question: currentQuestion.question,
                type: currentQuestion.type,
                correct_answers: currentQuestion.correct_answers
            }) !== JSON.stringify({
                question: questionData.question,
                type: questionData.type,
                correct_answers: questionData.correct_answers
            })
            : true;
    
        try {
            let questionId = currentQuestion?.id;
    
            if (questionModified || !currentQuestion) {
                const questionResult = await dispatch(addEditQuestion(questionData)).unwrap();
                questionId = questionResult.responseData?.id_db || currentQuestion?.id || questionResult.responseData?.id;
    
                dispatch(setCurrentQuestion({
                    ...questionData,
                    id: questionId
                }));
            }
    
            const updatedResponses = await Promise.all(
                responses.map(async (response, index) => {
                    const responseData = {
                        id: response.id || null,
                        id_question: questionId,
                        value: response.value,
                        pos: index + 1
                    };
    
                    const originalResponse = currentQuestion?.options.find(opt => opt.id === response.id);
    
                    const responseModified = !originalResponse ||
                        JSON.stringify({
                            value: originalResponse.value,
                            pos: originalResponse.pos
                        }) !== JSON.stringify({
                            value: response.value,
                            pos: responseData.pos
                        });
    
                    if (responseModified || !response.id) {
                        const responseResult = await dispatch(addEditQuestionAnswers(responseData)).unwrap();
                        return { ...response, id: responseResult.responseData?.id_db || response.id, pos: index + 1 };
                    }
                    return response;
                })
            );
            
    
            const correctAnswers = updatedResponses
                .filter(response => response.isTrue)
                .map(response => response.id); 
    
            if (correctAnswers.length > 0) {
                const updatedQuestionData = {
                    ...questionData,
                    correct_answers: correctAnswers,
                    id: questionId 
                };
    
                await dispatch(addEditQuestion(updatedQuestionData)).unwrap(); 
                showSuccessToast("Question saved successfully.");
            }


    
            const responsesModified = JSON.stringify(currentQuestion?.options || []) !== JSON.stringify(updatedResponses);
    
            if (questionModified || responsesModified) {
                let updatedQuestions;
                if (!currentQuestion) {
                    updatedQuestions = [...element.questions, { ...questionData, id: questionId, options: updatedResponses, correct_answers: correctAnswers }];
                } else {
                    updatedQuestions = element.questions.map((q) =>
                        q.id === currentQuestion.id
                            ? { ...questionData, id: questionId, options: updatedResponses, correct_answers: correctAnswers }
                            : q
                    );
                }
    
                dispatch(addEditAptitude({
                    id: element.id,
                    nom: element.label,
                    coef: element.coef,
                    pos: element.pos,
                    description: element.description,
                    type: element.type,
                    id_category_default: element.id_category_default,
                    visibility: element.visibility,
                    aspect: element.aspect,
                    questions: updatedQuestions 
                }));
    
                dispatch(setSelectedAptitudeForQuestion({
                    ...element,
                    questions: [...updatedQuestions] 
                }));
            }
    
            closeQuestionDialog();
        } catch (error) {
            console.error('Failed to save question or update aptitude:', error);
        }
    };
    

    const closeQuestionDialog = () => {
        dispatch(setAddQuestionModalVisibility(false));
        dispatch(setCurrentQuestion(null));
    };

    const setTypeQuestions = (type) => {
        if (type === 'single') {
            const updatedResponses = responses.map((response, index) => ({
                ...response,
                isTrue: index === 0 ? response.isTrue : false
            }));
            setResponses(updatedResponses);
        }
        setTypeQuestionsState(type);
    };


    const isSaveDisabled = !questionNom || responses.length < 2 || responses.some(r => !r.value) || !responses.some(r => r.isTrue);

    return (
        <Dialog
            header={currentQuestion ? "Edit Question" : "Add Question"}
            visible={questionDialogVisible}
            style={{ width: '58vw' }}
            onHide={closeQuestionDialog}
            footer={() => (
                <Row className="align-items-center">
                    <Col xs="auto" className="me-auto">
                        <PrimaryButton
                            title="Cancel"
                            onClick={closeQuestionDialog}
                            active={!isSaveDisabled}
                        />
                    </Col>
                    <Col xs="auto">
                        <PrimaryButton
                            title="Save"
                            onClick={handleSave}
                            active={!isSaveDisabled}
                        />
                    </Col>
                </Row>
            )}
        >
            <div>
                <Row className="align-items-center">
                    <Col lg={2} className="">
                        Name
                    </Col>
                    <Col lg={10} className="my-2 d-flex justify-content-center">
                        <InputText
                            value={questionNom}
                            onChange={(e) => setQuestionNom(e.target.value)}
                            style={{ width: "74%" }}
                            placeholder={currentQuestion ? "Edit question name" : "Enter question name"}
                        />
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col lg={2} className="">
                        Type
                    </Col>
                    <Col lg={10} className="my-2 d-flex justify-content-center">
                        <Dropdown
                            value={typeQuestions}
                            options={typesQuestionsOptions}
                            onChange={(e) => setTypeQuestions(e.value)}
                            placeholder="Select question type"
                            style={{ width: '74%' }}
                        />
                    </Col>
                </Row>

                {responses.map((response, index) => (
                    <React.Fragment key={index}>
                        <Row className="align-items-center">
                            <Col lg={2} className="">
                                Response {index + 1}
                            </Col>
                            <Col lg={7} className="ps-5 my-2 d-flex justify-content-center">
                                <InputText
                                    value={response.value}
                                    onChange={(e) => updateResponse(index, 'value', e.target.value)}
                                    style={{ width: "75%" }}
                                    placeholder={`Response ${index + 1}`}
                                />
                            </Col>
                            <Col lg={1} className="ps-2 my-2 d-flex align-items-center">
                                <Checkbox
                                    inputId={`response_${index}`}
                                    checked={response.isTrue}
                                    onChange={(e) => updateResponse(index, 'isTrue', e.checked)}
                                    disabled={typeQuestions === 'single' && response.isTrue && responses.filter(r => r.isTrue).length === 1}
                                />
                                <label htmlFor={`response_${index}`} className="ms-1 ml-2">True</label>
                            </Col>
                            <Col lg={1} className="ps-3 my-2 d-flex align-items-center">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-text"
                                    onClick={() => removeResponse(index)}
                                    aria-label={`Remove Response ${index + 1}`}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                ))}
                <Col lg={2} className="my-3">
                    <PrimaryButton
                        title="Add Response"
                        onClick={addResponse}
                        active={true}
                    />
                </Col>
            </div>
        </Dialog>
    );
};

export default AddQuestionsModal;
